import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sf-widget" }
const _hoisted_2 = { class: "sf-title" }
const _hoisted_3 = { class: "investNameRow" }
const _hoisted_4 = { class: "sf-filtersContainer" }
const _hoisted_5 = { class: "sf-f1" }
const _hoisted_6 = { class: "sf-f2" }
const _hoisted_7 = { class: "sf-f3" }
const _hoisted_8 = { class: "sf-f4" }
const _hoisted_9 = { class: "sf-f5" }
const _hoisted_10 = { class: "sf-f6" }
const _hoisted_11 = { class: "sf-f7" }
const _hoisted_12 = { class: "sf-f8" }
const _hoisted_13 = { class: "summaryContainer" }
const _hoisted_14 = { class: "summaryItem summary1 shadow" }
const _hoisted_15 = { class: "summaryItem summary2 shadow" }
const _hoisted_16 = { class: "summaryItem summary3 shadow" }
const _hoisted_17 = { class: "bold" }
const _hoisted_18 = { class: "summaryFinish" }
const _hoisted_19 = { class: "text-center" }
const _hoisted_20 = {
  key: 0,
  style: {"display":"absolute"}
}
const _hoisted_21 = { style: {"z-index":"-9999","width":"1px","height":"1px","opacity":"0"} }
const _hoisted_22 = { ref: "pdfContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumberButtons = _resolveComponent("InputNumberButtons")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_PdfTemplate = _resolveComponent("PdfTemplate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_InputText, {
            inputValue: _ctx.investName,
            description: "",
            onChangedVal: _ctx.changedInvestName
          }, null, 8, ["inputValue", "onChangedVal"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.purchasePrice,
            description: "CENA ZAKUPU [PLN]",
            minValue: 50000,
            maxValue: 2000000,
            step: 50000,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedPurchasePrice
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.courtFees,
            description: "OPŁATY NOTARIALNE",
            minValue: 100,
            maxValue: 10000,
            step: 100,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedCourtFees
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.salesCommission,
            description: "PROWIZJA BIURA [%]",
            minValue: 0,
            maxValue: 100,
            step: 0.5,
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedSalesCommission
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_InputSelect, {
            optionsData: _ctx.pccTaxItems,
            default: '2 %',
            description: "PODATEK PCC [%]",
            onChangedVal: _ctx.changedPccTax,
            class: "creditPurpose"
          }, null, 8, ["optionsData", "onChangedVal"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.renovationCosts,
            description: "KOSZTY REMONTU [PLN]",
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedRenovationCosts
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.monthlyCosts,
            description: "MIESIĘCZNE KOSZTY",
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedMonthlyCosts
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.monthlyRent,
            description: "MIESIĘCZNY CZYNSZ",
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedMonthlyRent
          }, null, 8, ["inputValue", "onChangedVal"]))
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_openBlock(), _createBlock(_component_InputNumberButtons, {
            inputValue: _ctx.annualOccupancy,
            description: "ROCZNE OBŁOŻENIE [%]",
            key: _ctx.inputForceUpdate,
            onChangedVal: _ctx.changedAnnualOccupancy
          }, null, 8, ["inputValue", "onChangedVal"]))
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Koszty", -1)),
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createTextVNode(" Prowizja biura: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.salesCommissionVal, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[2] || (_cache[2] = _createTextVNode(" Podatek PCC: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.pccTaxVal, "PLN")), 1)
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "summaryFinish" }, "PONIESIONE KOSZTY:", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
            _cache[5] || (_cache[5] = _createTextVNode(" ŁĄCZNIE: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalCosts, "PLN")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Przychód najmu", -1)),
          _createElementVNode("div", null, [
            _cache[8] || (_cache[8] = _createTextVNode(" Roczne koszty: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.annualCost, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[9] || (_cache[9] = _createTextVNode(" Obłożenie w skali roku: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.annualOccupancy) + " %", 1)
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", null, [
            _cache[10] || (_cache[10] = _createElementVNode("span", { class: "summaryFinish" }, "PRZYCHÓD BRUTTO: ", -1)),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.annualIncome, "PLN")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[15] || (_cache[15] = _createElementVNode("h2", null, "Dochód i rentowność *", -1)),
          _createElementVNode("div", null, [
            _cache[13] || (_cache[13] = _createTextVNode(" Rentowność: ")),
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.profitability) + " %", 1)
          ]),
          _createElementVNode("div", null, [
            _cache[14] || (_cache[14] = _createTextVNode(" Dochód operacyjny (rocznie): ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.operationalIncome, "PLN")), 1)
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_18, "OKRES ZWROTU: " + _toDisplayString(_ctx.paybackPeriod) + " lat", 1),
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "smallInfo" }, "* przed opodatkowaniem", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("button", {
          class: "sf-mortgageCheckBtn shadow",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.printPdf && _ctx.printPdf(...args)))
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("div", { class: "sf-mortgageCheckBtnContainer" }, [
            _createElementVNode("div", null, "pobierz PDF")
          ], -1)
        ]))
      ]),
      (_ctx.pdfGenerating)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_PdfTemplate, {
                  generationDate: _ctx.generationDate,
                  investName: _ctx.investName,
                  purchasePrice: _ctx.purchasePrice,
                  courtFees: _ctx.courtFees,
                  salesCommission: _ctx.salesCommission,
                  salesCommissionVal: _ctx.salesCommissionVal,
                  pccTax: _ctx.pccTax,
                  pccTaxVal: _ctx.pccTaxVal,
                  renovationCosts: _ctx.renovationCosts,
                  totalCosts: _ctx.totalCosts,
                  monthlyCosts: _ctx.monthlyCosts,
                  annualOccupancy: _ctx.annualOccupancy,
                  monthlyRent: _ctx.monthlyRent,
                  annualCost: _ctx.annualCost,
                  annualIncome: _ctx.annualIncome,
                  profitability: _ctx.profitability,
                  operationalIncome: _ctx.operationalIncome,
                  paybackPeriod: _ctx.paybackPeriod
                }, null, 8, ["generationDate", "investName", "purchasePrice", "courtFees", "salesCommission", "salesCommissionVal", "pccTax", "pccTaxVal", "renovationCosts", "totalCosts", "monthlyCosts", "annualOccupancy", "monthlyRent", "annualCost", "annualIncome", "profitability", "operationalIncome", "paybackPeriod"])
              ], 512)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}