import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pdfPage" }
const _hoisted_2 = { class: "text-right" }
const _hoisted_3 = { class: "printInvestName" }
const _hoisted_4 = { class: "rowPrint" }
const _hoisted_5 = { class: "bold color1" }
const _hoisted_6 = { class: "rowPrint" }
const _hoisted_7 = { class: "rowPrint" }
const _hoisted_8 = { class: "rowPrint" }
const _hoisted_9 = { class: "bold" }
const _hoisted_10 = { class: "rowPrint" }
const _hoisted_11 = { class: "rowPrint" }
const _hoisted_12 = { class: "bold" }
const _hoisted_13 = { class: "rowPrint" }
const _hoisted_14 = { class: "rowPrint bold" }
const _hoisted_15 = { class: "color1" }
const _hoisted_16 = { class: "rowPrint" }
const _hoisted_17 = { class: "rowPrint" }
const _hoisted_18 = { class: "rowPrint" }
const _hoisted_19 = { class: "rowPrint" }
const _hoisted_20 = { class: "rowPrint" }
const _hoisted_21 = { class: "rowPrint" }
const _hoisted_22 = { class: "bold color1" }
const _hoisted_23 = { class: "rowPrint" }
const _hoisted_24 = { class: "bold" }
const _hoisted_25 = { class: "rowPrint" }
const _hoisted_26 = { class: "rowPrint" }
const _hoisted_27 = { class: "footerPrint" }
const _hoisted_28 = { class: "genDate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSpeedfin = _resolveComponent("IconSpeedfin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconSpeedfin)
    ]),
    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "printTitle1" }, "Podsumowanie wyliczeń", -1)),
    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "printTitle2" }, "ROI INWESTYCJI", -1)),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.investName), 1),
    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "catTitle" }, "Informacje:", -1)),
    _createElementVNode("div", _hoisted_4, [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, "Cena zakupu nieruchomości:", -1)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.showAsCurrency(_ctx.purchasePrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[1] || (_cache[1] = _createElementVNode("div", null, "Opłaty notarialne i sądowe:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.courtFees, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, "Prowizja dla biura nieruchomości:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.salesCommission) + " %", 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[3] || (_cache[3] = _createElementVNode("div", null, "Kwota prowizji:", -1)),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.showAsCurrency(_ctx.salesCommissionVal, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[4] || (_cache[4] = _createElementVNode("div", null, "Podatek PCC:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.pccTax) + " %", 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[5] || (_cache[5] = _createElementVNode("div", null, "Kwota podatku:", -1)),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.showAsCurrency(_ctx.pccTaxVal, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[6] || (_cache[6] = _createElementVNode("div", null, "Koszty remontu:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.renovationCosts, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[7] || (_cache[7] = _createElementVNode("div", null, "ŁĄCZNIE PONIESIONE KOSZTY:", -1)),
      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.showAsCurrency(_ctx.totalCosts, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _cache[8] || (_cache[8] = _createElementVNode("div", null, "Miesięczne koszty:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.monthlyCosts, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _cache[9] || (_cache[9] = _createElementVNode("div", null, "Roczne obłożenie", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.annualOccupancy) + " %", 1)
    ]),
    _createElementVNode("div", _hoisted_18, [
      _cache[10] || (_cache[10] = _createElementVNode("div", null, "Miesięczny czynsz wynajmu:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.monthlyRent, "PLN")), 1)
    ]),
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "catTitle subtitle1" }, "Przychód z najmu:", -1)),
    _createElementVNode("div", _hoisted_19, [
      _cache[11] || (_cache[11] = _createElementVNode("div", null, "Roczne koszty:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.annualCost, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _cache[12] || (_cache[12] = _createElementVNode("div", null, "Obłożenie w skali roku:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.annualOccupancy) + " %", 1)
    ]),
    _createElementVNode("div", _hoisted_21, [
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "bold" }, "PRZYCHÓD BRUTTO (ROCZNIE):", -1)),
      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.showAsCurrency(_ctx.annualIncome, "PLN")), 1)
    ]),
    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "catTitle subtitle2" }, "Dochód i rentowność:", -1)),
    _createElementVNode("div", _hoisted_23, [
      _cache[14] || (_cache[14] = _createElementVNode("div", null, "Rentowność:", -1)),
      _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.profitability) + " %", 1)
    ]),
    _createElementVNode("div", _hoisted_25, [
      _cache[15] || (_cache[15] = _createElementVNode("div", null, "Dochód operacyjny (rocznie):", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.operationalIncome, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_26, [
      _cache[16] || (_cache[16] = _createElementVNode("div", null, "Okres zwrotu inwestycji (w latach):", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.paybackPeriod), 1)
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("span", null, "Wyliczenia na dzień: " + _toDisplayString(_ctx.generationDate), 1)
      ]),
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "bold text-right" }, [
        _createTextVNode(" Masz pytania? Skontaktuj się ze mną:"),
        _createElementVNode("br"),
        _createTextVNode("+48 723 404 404 ")
      ], -1))
    ])
  ]))
}